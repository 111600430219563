// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

//Fontawesome
$fa-font-path: "../webfonts";

// scss-docs-start theme-color-variables


$success:       #14bd83 !default;
$warning:       #ffd966 !default;
$dark:          #333f4f !default;


