// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// AdminLTE
@import '~admin-lte/dist/css/adminlte.css';

@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
// Select 2
@import '~select2/dist/css/select2.css';

.user {
    @extend %fa-icon;
    @extend .fas;

    &:before {
        content: fa-content($fa-var-user);
    }
}

.twitter {
    @extend %fa-icon;
    @extend .fab;

    &:before {
        content: fa-content($fa-var-twitter);
    }
}

.sidebar-dark-primary {
    background: $dark;
}

.tabbable  {
    .nav-tabs {
        overflow-x: auto;
        overflow-y:hidden;
        flex-wrap: nowrap;
        .nav-link {
            white-space: nowrap;
        }
    }
}

/* width */
::-webkit-scrollbar:horizontal {
    width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:horizontal {
    background: #555;
}




